<template>
    <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
        <v-card clas="elevation-1">
            <ValidationObserver ref="refFormDeduccion" v-slot="{invalid, validated}">
                <v-form name="form" @submit.prevent="save" lazy-validation>

                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click.native="handleModal">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>

                        <v-toolbar-title>Registrar nueva deducción</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-toolbar-items>
                            <v-btn class="danger" text type="submit" :disabled="invalid || !validated">
                            <v-icon class="ma-2" dense large>fas fa-save</v-icon>Guardar deducción</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text>
                        <v-container fluid>

                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete :items="tipoDeduccion" item-text="nombre" item-value="id" 
                                        v-model="deduccion.idTipoDeduccion" label="Tipo Deduccion*" prepend-icon="fas fa-list"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete :items="empleados" item-text="nombreCompleto" item-value="id" 
                                        v-model="deduccion.idEmpleado" label="Empleado*" prepend-icon="fas fa-user"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-text-field label="Concepto*" prepend-icon="fas fa-pen"
                                        required clearable v-model="deduccion.concepto"
                                        :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                               </v-col>

                               <v-col cols="12" sm="6" md="4">
                                    <v-autocomplete :items="tipoPago" item-text="nombre" item-value="clave"
                                    v-model="deduccion.tipoPago" label="Tipo de Pago*" prepend-icon="fas fa-list" disabled>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors, valid}">
                                        <v-text-field type="number" label="Monto*" prepend-icon="fas fa-dollar-sign"
                                        required v-model.number="deduccion.monto" min="1" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
 
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors, valid}">
                                        <v-text-field type="number" label="Cargo*" prepend-icon="fas fa-dollar-sign"
                                        required v-model.number="deduccion.cargo" min="1" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="4" md="4">
                                    <v-checkbox class="mx-2" v-model="deduccion.omitirPrimerPago" label="Omitir primer pago"></v-checkbox>
                                </v-col>
                            </v-row>
                            <small>* Indica son campos requeridos</small>

                        </v-container>
                    </v-card-text>

                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
    import EmpleadoDataService from '@/service/empleado/empleadoDataService';
    import DeduccionDataService from '@/service/deduccion/DeduccionDataService';

    export default {
        name: "NuevaDeduccion",
        props: {
            dialog: { default: false },
            handleModal: { type: Function },
            initialize: { type: Function },
            tipoDeduccion: { type: Array }
        },
        created() {
            this.getEmpleados();
        },
        data() {
            return {
                empleados: [],
                tipoPago: [{ clave: "TIPO_PAGO_SEMANAL", nombre: "Semanal" }],
                deduccion: {
                    id: null,               
                    concepto: "",
                    tipoPago: "TIPO_PAGO_SEMANAL",
                    monto: "",
                    cargo: "",
                    omitirPrimerPago: false,
                    idTipoDeduccion: null,
                    idEmpleado: null
                },

                searchDep: "",
            }
        },
        methods: {
            save() {
                this.$refs.refFormDeduccion.validate().then( isValid => {
                    if( isValid ) {
                        DeduccionDataService.create( this.deduccion ).then(_ => {
                            this.$refs.refFormDeduccion.reset();
                            this.handleModal();
                            this.initialize();
                            this.$swal.fire({ position: 'top-end', title: 'Deducción creada', text: 'La deducción se ha creado correctamente', icon: 'success', showConfirmButton: false, timer: 2000 })
                        }).catch( err => {
                            this.$swal.fire({
                                position: 'top-end', title: 'Error, Deducción no registrada', text: err.response,
                                icon: 'error', showConfirmButton: false, timer: 2000
                            });
                        });
                    }
                })
            },
            async getEmpleados() {
                this.empleados = await EmpleadoDataService.allDebtFree().then( resp => resp.data ) || [];
            }
        }
    }
</script>