import { http, authHeader } from '@/http-commons';

class DeduccionDataService {
    create( deduccion ){
        return http.post('api/v1/deduccion/create', deduccion, { headers : authHeader() });
    }
    table( params ){
        return http.get('api/v1/deduccion/table', { params, headers : authHeader() });
    }
}
export default new DeduccionDataService();