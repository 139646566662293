<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">

                <!-- Title and search -->
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nueva deducción
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-spacer></v-spacer>
                    <v-col cols="12" md="6" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search"
                        label="Buscar deducción por concepto o empleado" single-inline hide-details
                        @keydown.enter="searchDeduccion">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>

                <!-- Table -->
                <v-card-text>
                    <v-data-table dense :headers="filteredHeaders" :items="deducciones" sort-by="idRaya" class="elevation-1" 
                    :loading="loading" :server-items-length="totalElements" :items-per-page="itemsPerPage"
                    :page.sync="page" @page-count="totalPages = $event" 
                    hide-default-footer>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="showModalDetails(item)">fas fa-eye</v-icon>
                        </template>

                    </v-data-table>
                </v-card-text>

                <!-- Pagination -->
                <v-card-actions>
                    <PaginationNumber :itemsPerPage.sync="itemsPerPage" :pageCount.sync="totalPages"
                    :totalElements.sync="totalElements" :page.sync="page" :initialize.sync="initialize" />
                </v-card-actions>

            </v-card>
        </v-col>

        <ModalNew :dialog.sync="dialog" :initialize.sync="initialize" :handleModal="handleModal" :tipoDeduccion.sync="tipoDeduccion" v-if="dialog"/>
        <ModalDetails :dialogDetails.sync="dialogDetails" :handleModalDetails="handleModalDetails" :deduccionSelected.sync="deduccionSelected"  v-if="dialogDetails"/>
    </v-row>
</template>

<script>
    import { getParamsPagination } from '@/utils/pagination';
    import PaginationNumber from '@/components/shared/PaginationNumber.vue';
    import TipoDeduccionDataService from '@/service/deduccion/TipoDeduccionDataService';
    import DeduccionDataService from '../../service/deduccion/DeduccionDataService';

    import ModalNew from "./NuevaDeduccion.vue";
    import ModalDetails from "./MostrarDetalles.vue";
import Columns from '@/components/shared/Columns.vue';

    export default {
        name: "ListaDeduccion",
        components: { ModalNew, ModalDetails, PaginationNumber, Columns },
        created() {
            this.initialize();
            this.getAllTipoDeduccion();
        },
        data() {
            return {
                headers: [
                    { text: "Detalle", value: "actions", sortable: false, groupable: false, show: true },
                    { text: "ID", align: " d-none", sortable: false, value: "id", visible: false, groupable: false, show: false },
                    { text: "Empleado", value: "empleado.nombreCompleto", sortable: false, groupable: false, show: true },
                    { text: "Tipo de deducción", value: "tipoDeduccion.nombre", sortable: false, groupable: false, show: true },
                    { text: "Concepto", value: "concepto", sortable: false, groupable: false, show: true },
                    { text: "Monto", value: "monto", sortable: false, groupable: false, show: true },
                    { text: "Cargo", value: "cargo", sortable: false, groupable: false, show: true },
                    { text: "Abono", value: "abono", sortable: false, groupable: false, show: true },
                    { text: "Saldo", value: "saldo", sortable: false, groupable: false, show: true },
                ],
                search: "",
                page: 1,
                totalPages: 0,
                itemsPerPage: 25,
                totalElements: 0,
                loading: false,
                
                deducciones: [],
                tipoDeduccion: [],
                deduccionSelected: {},

                dialog: false,
                dialogDetails: false,
            }
        },
        methods: {
            // Table Methods
            searchDeduccion() {
                this.page = 1;
                this.initialize({ page: this.page, itemsPerPage: this.itemsPerPage });
            },
            initialize( options = {} ) {
                this.loading = true;
                this.itemsPerPage = options.itemsPerPage;
                const params = getParamsPagination({ options, search: this.search });

                DeduccionDataService.table(params).then( ({ data }) => {
                    const { rows, totalElements, totalPages, currentPage } = data;
                    this.deducciones = rows;
                    this.totalElements = totalElements;
                    this.page = currentPage + 1 ;
                    this.totalPages = totalPages;
                }).catch( err => {
                    this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos', text: err.response.data, showConfirmButton: false, timer: 3500 });
                }).finally(() => {
                    this.loading = false;
                });
            },

            // Modal Methods
            handleModal() { this.dialog = !this.dialog; },
            handleModalDetails() { this.dialogDetails = !this.dialogDetails; },
            showModalDetails( item ) {
                this.deduccionSelected = item;
                this.dialogDetails = true;
            },

            // Async Methods
            async getAllTipoDeduccion(){ this.tipoDeduccion =  await TipoDeduccionDataService.lstTipoDeduccion().then( resp => resp.data ) || [] },
        },
        computed: {
            filteredHeaders() { return this.headers.filter(header => header.show); }
        }
    }
</script>
