<template>
    <v-row align="center">
        <ModalNuevaAsistencia :dialog.sync="dialog" :lstAsistencia.sync="initTableAsistencia" :handleModal="handleModal" v-if="dialog"/>
        <ExcelExportAsistencia :dialog.sync="dialogExcelExport" :handleModal="handleExcelExport" v-if="dialogExcelExport"/>

        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">

                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="cyan darken-4" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nueva asistencia
                        </v-btn>

                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-col cols="12" md="8" sm="12" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar asistencia por fecha" single-inline hide-details @keydown.enter="searchAsistencia"></v-text-field>

                        <v-btn icon class="mt-4 ml-2"><v-icon dense>fas fa-sync</v-icon></v-btn>

                        <v-tooltip left color="success" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="mt-4 ml-2" color="success" v-bind="attrs" v-on="on" dark @click="handleExcelExport">
                                    <v-icon dark left>fas fa-file-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Reporte asistencias</span>
                        </v-tooltip>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>

                <v-data-table dense :headers="filteredHeaders" :items="asistencias" class="elevation-1"
                    :loading="loading" :options.sync="options"
                    :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                    :server-items-length="totalAsistencias">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="detalleAsistencia(item)" v-if="item.activo">details</v-icon>
                    </template>

                    <template v-slot:[`item.fechaAsistencia`]="{item}">
                        {{ new Date(item.fechaAsistencia).toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }) }}
                    </template>
                </v-data-table>

            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ModalNuevaAsistencia from './NuevaAsistencia.vue';
import AsistenciaDataService from '../../service/empleado/asistenciaDataService';
import { getParamsPagination } from '@/utils/pagination';
import ExcelExportAsistencia from './ExcelExportAsistencia.vue';
import Columns from '@/components/shared/Columns.vue';

export default {
    name: 'ListaAsistencia',
    components: { ModalNuevaAsistencia, ExcelExportAsistencia, Columns },
    created() {
        this.initTableAsistencia();
    },
    data() {
        return {
            asistencias: [],
            options: {},
            totalAsistencias: 0,
            loading: false,
            search: '',

            dialog: false,
            dialogExcelExport: false,

            headers: [
                { text: 'ID', align:' d-none',  sortable: false, value: 'id', visible: false, groupable: false, show: false },
                { text: 'Empleado',  value: 'empleado.nombreCompleto', align: 'start', sortable: false, groupable: false, show: true },
                { text: 'Fecha Asistencia', value: 'fechaAsistencia', align: 'start', sortable: false, groupable: false, show: true },
            ]
        }
    },
    methods: {
        searchAsistencia(){
            this.options.page = 1;
            this.initTableAsistencia(this.options);
        },
        initTableAsistencia( options = {} ){
            this.loading = true;
            const params = getParamsPagination({ options, search: this.search });

            AsistenciaDataService.table( params ).then((resp) => {
                const { rows, totalElements } = resp.data;
                this.asistencias = rows;
                this.totalAsistencias = totalElements;
            }).catch((err) => {
                this.$swal('Error', err.response, 'error');
            }).finally(() => {
                this.loading = false;
            });
        },
        handleModal(){ this.dialog = !this.dialog; },
        handleExcelExport(){ this.dialogExcelExport = !this.dialogExcelExport },
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableAsistencia(val);
                }
            }, deep: true
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter((item) => item.show); }
    }
}
</script>