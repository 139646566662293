<template>
    <v-row justify="center">
        <v-dialog v-model="dialogDetails" persistent transition="dialog-bottom-transition">
            <v-card class="elevation-4">

                <!-- Title -->
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click.native="handleModalDetails">
                        <v-icon>fas fa-times-circle</v-icon>
                    </v-btn>

                    <v-toolbar-title>
                        <div class="text-h6 font-weight-bold white--text">
                            Detalles de abonos del concepto: {{ deduccionSelected.concepto }}
                        </div>
                    </v-toolbar-title>
                </v-toolbar>

                <v-card-title>
                    <v-spacer></v-spacer>
                </v-card-title>

                <!-- Table -->
                <v-card-text>
                    <v-data-table dense :headers="headers" :items="detailsList" sort-by="idAbonoDeduccion" class="elevation-1" 
                    :server-items-length="totalElements" :items-per-page="itemsPerPage"
                    :page.sync="page" @page-count="totalPages = $event" 
                    hide-default-footer>

                        <template v-slot:[`item.monto`]="{ item }">
                            <span>{{ formatNumbertToMoney(item.monto) }}</span>
                        </template>
                        
                        <template v-slot:[`item.rayaDetalle.raya.fechaInicio`]="{ item }">
                            <span>{{ formatDatesToPeriod(item.rayaDetalle.raya.fechaInicio, item.rayaDetalle.raya.fechaFin) }}</span>
                        </template>

                    </v-data-table>
                </v-card-text>

                <!-- Pagination -->
                <v-card-actions>
                    <PaginationNumber :itemsPerPage.sync="itemsPerPage" :pageCount.sync="totalPages"
                    :totalElements.sync="totalElements" :page.sync="page" :initialize.sync="initialize" />
                </v-card-actions>

            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import { parseDateNatural } from '@/utils/DateUtil';
    import { getFormatCurrency } from '@/utils/CurrencyUtil';
    import { getParamsPagination } from '@/utils/pagination';
    import PaginationNumber from '@/components/shared/PaginationNumber.vue';
    import AbonoDeduccionDataService from '@/service/deduccion/AbonoDeduccionDataService';

    export default {
        name: "MostrarDetalles",
        props: {
            dialogDetails: { type: Boolean, default: false },
            handleModalDetails: { type: Function },
            deduccionSelected: { type: Object, default: {} },
        },
        components: { PaginationNumber },
        created() {
            this.initialize();
        },
        data() {
            return {
                headers: [
                    { text: "id", align: " d-none", sortable: false, value: "id", visible: false },
                    { text: "Monto", value: "monto", sortable: false },
                    { text: "Periodo de pago", value: "rayaDetalle.raya.fechaInicio", sortable: false },
                ],
                search: "",
                page: 1,
                totalPages: 0,
                itemsPerPage: 25,
                totalElements: 0,

                detailsList: [],
            }
        },
        methods: {
            // Table Methods
            searchAbonoDeduccion() {
                this.page = 1;
                this.initialize({ page: this.page, itemsPerPage: this.itemsPerPage });
            },
            initialize( options = {} ) {
                const params = getParamsPagination({ options, search: this.search });
                this.itemsPerPage = options.itemsPerPage;

                AbonoDeduccionDataService.tableByDeduccion(this.deduccionSelected.id, params).then( ({ data }) => {
                    const { rows, totalElements, totalPages, currentPage } = data;
                    this.detailsList = rows;
                    this.totalElements = totalElements;
                    this.page = currentPage + 1 ;
                    this.totalPages = totalPages;
                }).catch( err => {
                    this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos', text: err.response.data, showConfirmButton: false, timer: 2500 });
                });
            },
            formatDatesToPeriod( startDate, endDate ) {
                const formattedStartDate = parseDateNatural(startDate);
                const formattedEndDate = parseDateNatural(endDate);

                return `del ${formattedStartDate} al ${formattedEndDate}`
            },
            formatNumbertToMoney( number ) {
                const formattedNumber = parseFloat(number);
                return getFormatCurrency(formattedNumber, "$");
            }
        }
    }
</script>
