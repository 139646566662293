<template>
    <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
        <v-card clas="elevation-1">
            <v-form name="form" @submit.prevent="save" lazy-validation>

                <v-toolbar dark color="primary">
                    <v-btn icon dark @click.native="handleModal">
                        <v-icon>fas fa-times-circle</v-icon>
                    </v-btn>

                    <v-toolbar-title>Registrar nueva asistencia</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-toolbar-items>
                        <v-btn class="danger" text type="submit" :disabled="asistencias.length === 0">
                        <v-icon class="ma-2" dense large>fas fa-save</v-icon>Guardar asistencia</v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-container fluid>

                        <v-row>
                            <v-col cols="12">
                                <div>
                                    <p>Empleado*</p>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                            <v-text-field v-model="search" label="Buscar empleados" append-icon="fas fa-search"
                                                clearable filled dense class="mt-2 mb--2" hide-details>
                                            </v-text-field>
                                        </v-col>

                                        <v-col v-for="(empleado) in filterEmployee" :key="empleado.id" cols="12" sm="6" md="4">
                                            <v-checkbox 
                                                style="margin: 0px; padding: 0px;"
                                                v-model="asistencias"
                                                :label="empleado.nombreCompleto"
                                                :value="empleado.id"
                                                prepend-icon="fas fa-user-tie">
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <small style="display: block; margin-top: 20px;">* Indica son campos requeridos</small>

                    </v-container>
                </v-card-text>

            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import asistenciaDataService from '@/service/empleado/asistenciaDataService';
import empleadoDataService from '@/service/empleado/empleadoDataService';

export default {
    name: "NuevaAsistencia",
    props: {
        dialog: { default: false },
        handleModal: { type: Function },
        lstAsistencia: { type: Function }
    },
    created() {
        this.getEmpleados();
    },
    data() {
        return {
            empleados: [],
            asistencias: [],

            searchDep: "",
            search: "",
        }
    },
    methods: {
        save() {
            if( this.asistencias.length > 0 ) {
                asistenciaDataService.create( this.asistencias ).then( response => {
                    if ( response.status === 200 || response.status === 201 ) {
                        this.handleModal();
                        this.lstAsistencia();
                        this.$swal.fire({ position: 'top-end', title: 'Asistencia creada', text: 'La asistencia se ha creado correctamente', icon: 'success', showConfirmButton: false, timer: 2000 })
                    }
                }).catch( err => {
                    this.$swal.fire({
                        position: 'top-end', title: 'Error, Asistencia no registrada', text: err.response,
                        icon: 'error', showConfirmButton: false, timer: 2000
                    });
                });
            }
        },
        async getEmpleados() {
            this.empleados = await empleadoDataService.allWithoutAssistance().then( resp => resp.data ) || [];
        },
        searchEmployee(e) {
            e.preventDefault();
        }
    },
    computed: {
        filterEmployee() {
            if (this.search == '') return this.empleados;

            return this.empleados.filter((item) => {
                return item.nombreCompleto.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    },
}
</script>